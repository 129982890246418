export default (input: string, shouldRemoveAll = true) => {
  // I tried to combine them into one regex but it wasn't working on react native
  // and I didn't want to spend more time on it.
  const regexGlobal =
    /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu;
  const regexSingle =
    /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/u;

  const regex = shouldRemoveAll ? regexGlobal : regexSingle;

  const inputWithoutEmoji = input.replace(regex, '').trim();

  if (shouldRemoveAll) {
    return inputWithoutEmoji;
  }

  // In `getEmojiFromStartOfString.ts`, we add `\uFE0F` to the emoji to make sure
  // it's rendered as an emoji and not text. When we remove 1 emoji from the string,
  // we need to remove the `\uFE0F` as well.
  return inputWithoutEmoji.replace(/(\uFE0F\s?)/g, '');
};
